import ListTable from 'Common/ListTable/ListTable';
import ScrollbarTab from 'Common/ScrollbarTab/ScrollbarTab';
import http from 'Infrastructure/HttpService/http.service';
import React, { FC, useEffect, useState } from 'react';

import AuctionResultsEntry from './AuctionResultsEntry';
import AuctionResultsHeader from './AuctionResultsHeader';

export interface IApiVehicleDetailsViewModel {
    auctionName: string;
    bodyType: string;
    capClean?: number;
    engineSize: string;
    formattedRegDate: string;
    grade: string;
    hammerPrice: number;
    inspectionURL: string;
    lot: string;
    make: string;
    mileage?: number;
    mileageText: string;
    model: string;
    refNum: string;
    regDate?: string;
    regExpDate: string;
    regNo: string;
    reserve: string;
    saleDateTime: string;
    saleResult: string;
    saleResultClass: string;
    saleTime: string;
    siteId: string;
    siteName: string;
    svvunitId: number;
    variant: string;
    warrantedText: string;
}

interface IAuctionResultsProps {
    bearerToken: string;
    refNum: string;
    currentLotNumber: string;
}

const AuctionResults: FC<IAuctionResultsProps> = ({ bearerToken, refNum, currentLotNumber }) => {
    const [auctionResultDetails, setAuctionResultDetails] = useState<IApiVehicleDetailsViewModel[]>([]);

    useEffect(() => {
        const fetchResults = async () => {

            try {

                const response = await http.get(`/Api/Vehicle/VehicleSold?refNum=${refNum}`, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`
                    }
                });
                const { data } = response;

                setAuctionResultDetails(data);
            } catch (error) {
                setAuctionResultDetails([]);
            }
        };

        fetchResults();
    }, [currentLotNumber, refNum]);

    return (
        <ScrollbarTab>
            <ListTable>
                <>
                    <AuctionResultsHeader />
                    {auctionResultDetails?.map((lot, index) => (
                        <AuctionResultsEntry key={index} lot={lot} />
                    ))}
                </>
            </ListTable>
        </ScrollbarTab>
    );
};

export default AuctionResults;
