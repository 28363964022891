import NoResult from 'Common/NoResult/NoResult';
import http from 'Infrastructure/HttpService/http.service';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from './inspection-report-html.module.scss'

interface IInspectionReportHtml {
    svvUnitId?: number;
}

const InspectionReportHtml: FC<IInspectionReportHtml> = ({ svvUnitId }) => {
    const [loadingState, setLoadingState] = useState<('loading' | 'loaded' | 'notFound' | 'error')>('loading');
    const { bearerToken } = useSelector((state: AppState) => state.account);
    const iframe = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (svvUnitId !== undefined) {
            http.get<string>(
                `api/inspection/${svvUnitId}`,
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`
                    }
                }
            ).then(response => {
                const { data } = response;
                const content = iframe.current?.contentWindow;

                if (content) {
                    content.document.open();
                    content.document.write(data);
                    content.document.close();
                    setLoadingState('loaded');
                }
            }).catch((error) => {

                if (error.response.status == 404) {
                    setLoadingState('notFound');
                } else {
                    setLoadingState('error');
                }
            });
        }
    }, [svvUnitId]);

    if (svvUnitId === undefined) {
        return null;
    } else if (loadingState === "notFound") {
        return <NoResult><>There is no Inspection Report available for the current lot</></NoResult>;
    } else if (loadingState === "error") {
        return <NoResult><>There was a error when fetching the Inspection Report for the current lot</></NoResult>;
    } else {
        return (
            <div className={classes['inspection-report-container']}>
                <div className={classes['inspection-report']}>
                    <iframe ref={iframe} width="100%" height="100%" style={{ display: loadingState === "loaded" ? "block" : "none" }} ></iframe>
                </div>
                { loadingState === "loading" && (
                    <div className={classes['loading']}>
                        <NoResult><>Please wait while the report loads...</></NoResult>
                    </div>
                )}
            </div>
        );
    }
};

export default InspectionReportHtml;