import NotifyBadgeList from 'Common/NotifyBadgeIcon/NotifyBadgeList';
import AutoHeightScrollbar from 'Common/ScrollbarTab/AutoHeightScrollbar';
import TabContainer from 'Common/Tabs/TabContainer';
import TabContent from 'Common/Tabs/TabContent';
import TabLabel from 'Common/Tabs/TabLabel';
import Tabs from 'Common/Tabs/Tabs';
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { Sale } from 'Domain/Entities/sale.entity';
import { useGetUserLookerSuperUser } from 'Features/AuctionControls/auction-controls.hooks';
import AuctionControls from 'Features/AuctionControls/AuctionControls.container';
import BiddingDetails from 'Features/BiddingHistory/BiddingDetails.container';
import AnnouncementRemark from 'Features/MainSale/Components/CurrentLotDetails/AnnouncementRemark/AnnouncementRemark';
import { useGetMessagesAndCount } from 'Features/UserMessaging/chat-messaging.service';
import { setUnreadMessageCount } from 'Features/UserMessaging/chat-messaging.slice';
import UserMessaging from 'Features/UserMessaging/ChatMessaging.container';
import ChatUnreadMessageCount from 'Features/UserMessaging/Components/ChatUnreadMessageCount';
import VideoPlayer, { VideoPlayerType } from 'Features/VideoPlayer/VideoPlayer.container';
import http from 'Infrastructure/HttpService/http.service';
import VehicleGallery from 'Pages/Common/VehicleGallery/VehicleGallery';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientsOnlineSelectors, JoinedSale, joinedSalesSelectors, proxyClientsOnlineSelectors, SaleClientsOnline, SaleLots, saleLotsSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import { ISaleHistory } from '../../../Domain/Entities/ISalesHistory';
import { usePhenixWebRtcContext } from '../../PhenixVideoPlayer/PhenixWebRtcContext/PhenixWebRtcProvider';
import AllLots from '../Components/AllLotsTab/AllLots';
import AuctionResults from '../Components/AuctionResults/AuctionResults.container';
import ClientsOnline from '../Components/ClientsOnline/ClientsOnline.container';
import CurrentLotDetailsAccordion from '../Components/CurrentLotDetails/CurrentLotDetailsAccordion';
import PricingInformation from '../Components/CurrentLotDetails/PricingInformation';
import VehicleImages from '../Components/CurrentLotDetails/VehicleImages';
import InspectionReportHtml from '../Components/InspectionReport/InspectionReportHtml';
import InspectionReportPdf from '../Components/InspectionReport/InspectionReportPdf';
import AuctionHeader from '../Components/MainSaleHeader/AuctionHeader';
import LotHeaderLights from '../Components/MainSaleHeader/LotHeaderLights';
import LotTitle from '../Components/MainSaleHeader/LotHeaderTitle';
import ProxyBids from '../Components/ProxyBids/ProxyBids.container';
import UpcomingVehicles from '../Components/UpcomingVehicles/UpcomingVehicles.container';
import VendorLogo from '../Components/VendorLogo/VendorLogo';
import WinningLots from '../Components/WinningLots/WinningLots.container';
import { handleLeavePrimarySale } from '../main-sale.service';
import { getProxyBidsLotsList } from '../main-sale.slice';
import classes from './main-sale.module.scss';

interface IMainSaleView {
    isMobile: boolean;
    lots: any[];
    proxyBids: any[];
    currentLot: any;
    sale: Sale;
}

const MainSaleView: FC<IMainSaleView> = ({ isMobile, currentLot, lots, proxyBids, sale }) => {
    const { connection } = useWebSocketConnection();
    const dispatch: AppDispatch = useDispatch();
    const { bearerToken, clientLoginInfo, userRole } = useSelector((state: AppState) => state.account);
    const { joinedSales } = useSelector((state: AppState) => state.auctions);

    const { clientsOnline, proxyClientsOnline } = useSelector((state: AppState) => state.auctions);

    const { saleClientsOnline } = (clientsOnlineSelectors.selectById(clientsOnline, sale?.refNum) as SaleClientsOnline) || [];

    const { saleClientsOnline: saleProxyClientsOnline } = (proxyClientsOnlineSelectors.selectById(proxyClientsOnline, sale?.refNum) as SaleClientsOnline) || [];

    const { buyerNumber } = (joinedSalesSelectors.selectById(joinedSales, sale?.refNum) as JoinedSale) || {};

    //For Video Player Component;
    const { refNum = '', vidUrl = '', siteId = '' } = sale;
    const { vehicleImages = [] } = currentLot;
    //For Auction Headers
    const { saleDescription = '', saleDate = new Date() } = sale;
    const {
        extraDetails: { conditionReportUrl = undefined, checkPoint = '' } = {},
        lotDetails: { auctioneers = [], capAverage = '', capClean = '', capRetail = '' } = {},
        lightStatus: { vendorOnline = false } = {},
        vehicleServiceHistory = [],
        extraDetails = {}
    } = currentLot;

    //For Lot Headers
    const { lot = '', lotDetails = {}, lightStatus = {}, workOrder = '' } = currentLot;
    const { announcement = '', bodyType = '', derivative = '', inspectionFuelType = '', make = '',
        model = '', regNo = '', remarks = '', transmission = '', watching = '' } = lotDetails;

    const { saleLots } = useSelector((state: AppState) => state.auctions);

    const { inspectionDamageCost = '', residualValue = '', sureCheckText = '', sureCheckResult = '',
        svvunitId = undefined } = saleLotsSelectors.selectById(saleLots, refNum) ?
            (saleLotsSelectors.selectById(saleLots, refNum) as SaleLots).lotsList.find(x => x.workOrder === workOrder) || {} : {};

    const { v5 = null, sureCheck = null } = lightStatus;

    const { disposeChannelExpressObject } = usePhenixWebRtcContext();

    const [activeTab, setActiveTab] = useState('live-view');

    const [tabLabel, setTabLabel] = useState(isMobile ? 'live-view' : 'current-lot');

    const [saleHistory, setsaleHistory] = useState<ISaleHistory>();

    const { myUnreadMessageCount } = useGetMessagesAndCount(refNum);

    const { isGalleryOpen } = useSelector((state: AppState) => state.buyer);

    const isHideAllLotsOnMobile = (isMobile && JSON.stringify(process.env.REACT_APP_ENABLE_MOBILE_ALL_LOTS) != JSON.stringify("true"));

    const { account: { permissions: { viewHtmlInspectionReports = false } = {} } = {} } = useSelector((state: AppState) => state);

    let showSalesHistory = false;

    let isShowInspectionCost = false;

    let isShowSIVCost = false;

    if (userRole === 'vendor') {
        const vendorGVCMatch: boolean = lots.find(x => x.lot === lot)?.isMyLot;

        const consignerID = lots.find(x => x.lot === lot)?.consignerID;

        const gvcCodeExcludeList = process.env.REACT_APP_EXCLUDE_GVC_CODES;

        const gvcCodeExcludeArray = gvcCodeExcludeList?.split(/\s*,\s*/);

        const isGVCCodeExistInConfig = gvcCodeExcludeArray!.indexOf(consignerID) > -1;

        showSalesHistory = (JSON.stringify(process.env.REACT_APP_ENABLE_SALE_HISTORY) === JSON.stringify("true")) && vendorGVCMatch && !!svvunitId && !isGVCCodeExistInConfig;

        isShowInspectionCost = vendorGVCMatch && (JSON.stringify(process.env.REACT_APP_ENABLE_INSPECTION_COST) === JSON.stringify("true"));

        isShowSIVCost = vendorGVCMatch;
    }

    const handleCloseMainAuction = async (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const { refNum, siteId } = sale;
        const response = await connection?.invoke('LeaveSale', siteId, refNum, bearerToken, workOrder);

        if (response) {
            dispatch(handleLeavePrimarySale(refNum));

            disposeChannelExpressObject(refNum);
        }
    };

    useEffect(() => {
        setTabLabel(isMobile ? 'live-view' : 'current-lot');
        setActiveTab(isMobile ? 'live-view' : 'current-lot');
    }, [isMobile])

    const fetchSaleHistory = async () => {
        try {

            const response = await http.get(`/api/lot/saleeventlistinghistory?svvUnitId=${svvunitId}&externalSourceRef=${sale?.refNum}`, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`
                }
            });
            const { data } = response;

            setsaleHistory(data);
        } catch (error) {
            setsaleHistory(undefined);
        }
    };

    useEffect(() => {
        if (!showSalesHistory)
            return;
        fetchSaleHistory();
    }, [svvunitId]);

    const handleTabOnChange = (e: MouseEvent<HTMLDivElement>, label: string) => {
        e.preventDefault();

        setTabLabel(label);
    };

    const handleProxyBidRefresh = () => {
        dispatch(getProxyBidsLotsList(refNum));
    };

    const onClickMessagingTabMenu = () => {
        if (myUnreadMessageCount > 0)
            dispatch(setUnreadMessageCount({ refNum, value: 0 }));

        setActiveTab("messages");
    }

    const tabMenu = () => {
        return (
            <div className={classes['grid-item-tab-menu']}>
                <Tabs activeTab={tabLabel} onChange={handleTabOnChange}>
                    <TabLabel title="Live View" label="live-view" hideTab={!isMobile} cssClass="ui-live-view" activeTab={activeTab} onClick={setActiveTab} />
                    <TabLabel title="Current Lot" label="current-lot" cssClass="ui-current-lot" activeTab={activeTab} onClick={setActiveTab} />
                    {/*   GTM: inspection-report-trigger class required for GA. */}
                    <TabLabel title="Inspection Report" label="inspection-report" cssClass="ui-inspection-report" gtmInspectionReportClass="inspection-report-trigger" activeTab={activeTab} onClick={setActiveTab} />
                    <TabLabel title="All Lots" label="all-lots" cssClass="ui-all-lots" hideTab={isHideAllLotsOnMobile} activeTab={activeTab} onClick={setActiveTab} />
                    <TabLabel title="Winning Lots" label="winning-lots" cssClass="ui-all-lots" activeTab={activeTab} onClick={setActiveTab} hideTab={userRole !== 'buyer'} />
                    <TabLabel title="Proxy Bids" label="proxy-bids" hideTab={isMobile || userRole !== 'buyer'} />
                    <TabLabel title="Auction Results" label="auction-results" hideTab={isMobile || userRole !== 'vendor'} />
                    <TabLabel title="Clients Online" label="clients-online" hideTab={isMobile || clientLoginInfo?.ISU !== 'True'} />
                    <TabLabel title="Messages" label="messages" cssClass="ui-messages" hideTab={!isMobile} activeTab={activeTab} onClick={onClickMessagingTabMenu} >{isMobile ? <ChatUnreadMessageCount unreadCount={myUnreadMessageCount} /> : <></>}</TabLabel>
                </Tabs>
            </div>
        );
    }

    return (
        <>
            <div className={classes['grid-main-sale']}>

                <div className={classes['grid-item-sale-header']}>
                    <AuctionHeader
                        lane={sale.branch}
                        saleDescription={saleDescription}
                        saleDate={saleDate}
                        isMobile={isMobile}
                        auctioneers={auctioneers}
                        onCloseMainAuction={handleCloseMainAuction}
                    />
                    {isMobile && (
                        <div className={`${classes.cell} ${classes['text-right']}`}>
                            <NotifyBadgeList announcement={announcement} remarks={remarks} watching={watching} />
                        </div>
                    )}
                </div>
                <div className={classes['grid-item-lot-header']}>
                    <LotTitle
                        bodyType={bodyType}
                        fuelType={inspectionFuelType}
                        lot={lot}
                        make={make}
                        model={model}
                        derivative={derivative}
                        transmission={transmission}
                        vendorLogoUrl={lotDetails?.vendorLogoUrl ?? ''}
                        regNo={regNo}
                        isMobile={isMobile}
                    />

                    <LotHeaderLights
                        v5={v5}
                        surecheck={sureCheck}
                        surecheckText={sureCheckText}
                        sureCheckResult={sureCheckResult}
                        checkpoint={checkPoint}
                        isVendorOnline={vendorOnline}
                        announcement={announcement}
                        remarks={remarks}
                        watching={watching}
                        lotDetails={lotDetails}
                        isShowVendorOnline={isMobile}
                    />

                </div>


                {/*<!-- Main > Tabs --> */}
                {!isMobile && tabMenu()}
                <div className={classes['grid-item-tab-content']}>
                    {isMobile &&
                        (<div style={{ display: activeTab == 'live-view' ? 'block' : 'none' }}>
                            <VideoPlayer
                                key={refNum}
                                refNum={sale.refNum}
                                videoUrl={vidUrl}
                                vehicleImages={vehicleImages}
                                videoPlayerClass={VideoPlayerType.MOBILE_SALE}
                            />
                        </div>)}
                    <TabContainer activeTab={tabLabel}>
                        <TabContent key={'live-view'} label={'live-view'}>
                            <div id='pricing-bidding-container' className={classes['pricing-bidding-container']}>
                                <PricingInformation capRetail={capRetail} capAverage={capAverage} capClean={capClean}
                                    glassesGuide={currentLot?.extraDetails?.glassesGuide} siv={isShowSIVCost ? residualValue : null}
                                    inspectionDamageCost={isShowInspectionCost ? inspectionDamageCost : null} />
                                <BiddingDetails refNum={sale.refNum} />
                            </div>
                        </TabContent>
                        <TabContent key={'current-lot'} label={'current-lot'}>
                            {/*      GTM: current-lot-sections class required for GA. */}
                            <div className={`current-lot-sections`}>
                                {
                                    isMobile && <div className={`${classes['div-mobile-vendor-logo']}`}>
                                        <div>Vendor: {lotDetails?.vendor}</div>
                                        <VendorLogo cssClasses="img-mobile-vendor-logo" vendorLogoURL={lotDetails?.vendorLogoUrl ?? ''} />
                                    </div>
                                }

                                <AnnouncementRemark announcement={lotDetails?.announcement} remark={lotDetails?.remarks} />
                                <section className={classes['grid-item-section-current-lot-details']}>
                                    <AutoHeightScrollbar announcement={lotDetails?.announcement} remark={lotDetails?.remarks}>
                                        <div className={`scrollbar-v ${classes['current-lot-accordions-container']}`}>
                                            <CurrentLotDetailsAccordion onRefreshSaleHistory={fetchSaleHistory} saleHistory={saleHistory} lotDetails={lotDetails}
                                                vehicleServiceHistory={vehicleServiceHistory} showSalesHistory={showSalesHistory} extraLotDetails={extraDetails} />
                                        </div>
                                    </AutoHeightScrollbar>
                                    {!isMobile && (<div>
                                        <VehicleImages images={vehicleImages} isSlideShow={false} />
                                        <PricingInformation capRetail={capRetail} capAverage={capAverage} capClean={capClean}
                                            glassesGuide={currentLot?.extraDetails?.glassesGuide} siv={isShowSIVCost ? residualValue : null}
                                            inspectionDamageCost={isShowInspectionCost ? inspectionDamageCost : null} />
                                    </div>)}
                                    {!isMobile && isGalleryOpen && (vehicleImages.length) ?
                                        <VehicleGallery vehicleImages={vehicleImages} /> : null
                                    }
                                </section>
                            </div>
                        </TabContent>
                        <TabContent key={'inspection-report'} label={'inspection-report'}>
                            {
                                viewHtmlInspectionReports ? (
                                    <InspectionReportHtml svvUnitId={svvunitId} />
                                ) : (
                                    <InspectionReportPdf inspectionReportUrl={conditionReportUrl} />
                                )
                            }
                        </TabContent>
                        <TabContent key={'all-lots'} label="all-lots">
                            <AllLots allLots={lots} currentLotNumber={lot} />
                        </TabContent>
                        <TabContent key={'winning-lots'} label="winning-lots">
                            <WinningLots bearerToken={bearerToken} refNum={refNum} currentLotNumber={currentLot?.lot} />
                        </TabContent>
                        <TabContent key={'proxy-bids'} label="proxy-bids">
                            <ProxyBids onRefresh={handleProxyBidRefresh} proxyBidLots={proxyBids} />
                        </TabContent>
                        <TabContent key={'auction-results'} label="auction-results">
                            <AuctionResults bearerToken={bearerToken} refNum={refNum} currentLotNumber={currentLot?.lot} />
                        </TabContent>
                        <TabContent key={'clients-online'} label="clients-online">
                            <ClientsOnline clientsOnline={saleClientsOnline} proxyClientsOnline={saleProxyClientsOnline} />
                        </TabContent>
                        <TabContent key={'messages'} label="messages">
                            <UserMessaging refNum={refNum} siteId={siteId} lot={lot} />
                        </TabContent>
                    </TabContainer>
                </div>

                {!isMobile && (<>
                    <section className={classes['grid-item-right-container']}>
                        {!isMobile && <span className={classes['main-sale-right-border']} />}

                        <div className={`main-sale-video-panel`}>
                            <VideoPlayer
                                key={refNum}
                                refNum={sale.refNum}
                                videoUrl={vidUrl}
                                vehicleImages={vehicleImages}
                                videoPlayerClass={VideoPlayerType.MAIN_SALE}
                            />
                        </div>

                        <BiddingDetails refNum={sale.refNum}>
                            <AuctionControls refNum={refNum} siteId={siteId} buyerNumber={buyerNumber} lotDetails={lotDetails} />
                        </BiddingDetails>
                    </section>

                    <footer className={classes['grid-item-footer']}>
                        <section className={classes['footer-section-wrapper']}>
                            <UpcomingVehicles saleVehicles={lots} currentLot={lot} />
                            <UserMessaging refNum={refNum} siteId={siteId} lot={lot} />
                        </section>
                    </footer>
                </>)}

            </div>

            {isMobile && (<div id='mobile-footer' className={classes['mobile-footer']}>
                {(activeTab != 'messages') && <AuctionControls refNum={refNum} siteId={siteId} buyerNumber={buyerNumber} lotDetails={lotDetails} />}
                {tabMenu()}
            </div>)}

        </>
    );
};

MainSaleView.whyDidYouRender = true;

export default MainSaleView;
